import React, { useCallback, useState } from 'react'
import { Card, Button, Form, Modal, Tabs, Tab, OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';

import { useForm } from '../../hooks/useForm';
import { useValidator } from '../../hooks/useValidator';

import { useFetch } from '../../hooks/useFetch';
import { MsgInputServer } from '../MsgInput/MsgInputServer';
import { MsgInput } from '../MsgInput/MsgInput';

import { useLang } from '../../language';

import 'animate.css'
import './CardLogin.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { InputText } from '../Inputs/InputText';
import { ButtonSpinner } from '../ButtonSpinner/ButtonSpinner';
import { useEffect } from 'react';
import { InputSelect } from '../Inputs/InputSelect';
import { ModalStatus } from '../ModalStatus/ModalStatus';
import ModalProteccionDatos from '../Modal/ModalProteccionDatos';
import { TipoViasServices } from '../../controllers/services/statics.service';
import { getSelectByKeys, isBetweenDates } from '../../helpers/helpers';

import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

import _, { cloneDeep } from 'lodash';
import { ListAutocomplete } from '../ListAutocomplete/ListAutocomplete';

export const CardLogin = ({
    fetchLoginColegiado,
    fetchLoginColegiadoData,
    fetchLoginExterno,
    fetchLoginExternoData,
}) => {

    const Lang = useLang();

    // Cargar parametros necesarios

    const [fetchParametros, fetchParametrosData] = useFetch('GET', `/parametros`)
    // const [fetchParametrosPoblaciones, fetchParametrosPoblacionesData] = useFetch('GET', `/parametros/poblaciones`)

    const [fetchRegisterExterno, fetchRegisterExternoData] = useFetch('POST', `/clientes/auth/register`)

    const [fetchVerify, fetchVerifyData] = useFetch('POST', `/clientes/auth/verify`)

    useEffect(() => {

        fetchParametros()

    }, [])


    // Login Colegiados

    const [formLoginColegiado, handleInputChangeLoginColegiado] = useForm({
        num_colegiado: '',
        password: ''
    })

    const [valuesValidLogin, handleVerifiedValuesLogin] = useValidator({
        num_colegiado: {
            required: {
                isOk: true,
                msgError: [`El número de col·legiat és obligatori.`]
            }
        },
        password: {
            required: {
                isOk: true,
                msgError: [`La contrasenya és obligatòria.`]
            }
        }
    })

    const handleSubmitFormLoginColegiado = (e) => {

        e.preventDefault()

        if (handleVerifiedValuesLogin(formLoginColegiado)) {
            fetchLoginColegiado({
                body: formLoginColegiado
            })
        }
    }

    // Login Externo

    const [formLoginExterno, handleInputChangeLoginExterno] = useForm({
        documento: '',
        password: ''
    })

    const [valuesValidLoginExterno, handleVerifiedValuesLoginExterno] = useValidator({
        documento: {
            required: {
                isOk: true,
                msgError: [`El document és obligatori.`]
            }
        },
        password: {
            required: {
                isOk: true,
                msgError: [`La contrasenya és obligatòria.`]
            }
        }
    })

    const handleSubmitFormLoginExterno = (e) => {

        e.preventDefault()

        if (handleVerifiedValuesLoginExterno(formLoginExterno)) {
            fetchLoginExterno({
                body: formLoginExterno
            })
        }
    }

    // Registro Usuario externo

    const [showModalRegistro, setShowModalRegistro] = useState(false);

    const handleCloseModalRegistro = () => {
        handleResetValuesRegisterExterno()
        handleResetRegisterExterno()
        setShowModalRegistro(false)
    };
    const handleShowModalRegistro = () => setShowModalRegistro(true);

    const [formRegisterExterno, handleInputChangeRegisterExterno, handleFormChangeRegisterExterno, handleResetRegisterExterno] = useForm({
        nombre: '',
        apellidos: '',
        telefono: '',
        documento: '',
        email: '',
        password: '',
        password_confirmation: '',
        direccion: '',
        provincia: '',
        poblacion: '',
        codigo_postal: '',
        pais: '',
        tipo_documento: '',
        manifesto: '',
        calle: '',
    })

    const handleChangeManifesto = (e) => {
        console.log(e.target.checked)
        if (e.target.checked !== undefined) {
            handleFormChangeRegisterExterno({
                ...formRegisterExterno,
                manifesto: e.target.checked ? 'true' : ''
            })
        } else {
            handleFormChangeRegisterExterno({
                ...formRegisterExterno,
                manifesto: 'true'
            })
        }

        handleCloseModalProteccionDatos()
    }


    const [valuesValidRegisterExterno, handleVerifiedValuesRegisterExterno, handleResetValuesRegisterExterno] = useValidator({
        nombre: {
            required: {
                isOk: true,
                msgError: [`El nom és obligatori.`]
            }
        },
        apellidos: {
            required: {
                isOk: true,
                msgError: [`El nom és obligatori.`]
            }
        },
        telefono: {
            required: {
                isOk: true,
                msgError: [`El telèfon és obligatori.`]
            }
        },
        documento: {
            required: {
                isOk: true,
                msgError: [`El document és obligatori.`]
            }
        },
        email: {
            required: {
                isOk: true,
                msgError: [`El correu és obligatori.`]
            }
        },
        password: {
            required: {
                isOk: true,
                msgError: [`La contrasenya és obligatòria.`]
            }
        },
        password_confirmation: {
            required: {
                isOk: true,
                msgError: [`La confirmació de contrasenya és obligatòria.`]
            }
        },
        calle: {
            required: {
                isOk: true,
                msgError: [`L'adreça és obligatòria.`]
            }
        },
        provincia: {
            required: {
                isOk: true,
                msgError: [`La província és obligatòria.`]
            }
        },
        poblacion: {
            required: {
                isOk: true,
                msgError: [`La població és obligatòria.`]
            }
        },
        codigo_postal: {
            required: {
                isOk: true,
                msgError: [`El codi postal és obligatori.`]
            }
        },
        pais: {
            required: {
                isOk: true,
                msgError: [`El país és obligatori.`]
            }
        },
        tipo_documento: {
            required: {
                isOk: true,
                msgError: [`El tipus de document és obligatori.`]
            }
        },
        manifesto: {
            required: {
                isOk: true,
                msgError: [`Heu d'acceptar el manifest`]
            }
        }

    })

    const handleSubmitFormRegisterExterno = (e) => {

        console.log('formRegisterExterno', formRegisterExterno)

        e.preventDefault()

        if (handleVerifiedValuesRegisterExterno(formRegisterExterno)) {

            const bodyTemp = {
                ...formRegisterExterno
            }

            if (!bodyTemp.comarca) delete bodyTemp.comarca

            fetchRegisterExterno({
                body: {
                    ...bodyTemp,
                    manifesto: formRegisterExterno.manifesto === 'true' ? true : false
                }
            })
        }
    }

    const [showStatusVerify2, setShowStatusVerify2] = useState(false);

    useEffect(() => {
        console.log(fetchRegisterExternoData)

        if (fetchRegisterExternoData.data?.status === 'Success') {
            handleCloseModalRegistro()
            setShowStatusVerify2(true)
        }

    }, [fetchRegisterExternoData])

    // Recuperar Contraseña

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [fetchRecuperarPassword, fetchRecuperarPasswordData] = useFetch('POST', `/clientes/auth/reiniciar_password`)

    const [formLoginColegiadoRecuperarPassword, handleInputChangeRecuperarPassword] = useForm({
        nif: ''
    })

    const [valuesValidRecuperarPassword, handleVerifiedValuesRecuperarPassword] = useValidator({
        nif: {
            required: {
                isOk: true,
                msgError: [`El NIF és necessari.`]
            }
        }
    })

    const handleSubmitRecuperarPassword = e => {

        e.preventDefault()

        if (handleVerifiedValuesRecuperarPassword(formLoginColegiadoRecuperarPassword)) {

            fetchRecuperarPassword({
                body: formLoginColegiadoRecuperarPassword
            })
        }
    }

    const [showStatusRecuperarPassword, setShowStatusRecuperarPassword] = useState(false);

    useEffect(() => {

        if (fetchRecuperarPasswordData.data?.status === 'Success') {

            handleClose()
            setShowStatusRecuperarPassword(true)
        }

    }, [fetchRecuperarPasswordData.data?.status])

    // Cambiar idioma

    // const handdleChangeLanguage = (lang) => {

    //     dispatch({
    //         type: types.logout,
    //         ...user,
    //         lang
    //     })
    // }

    // Wizard

    const listWizard = [
        'Registre',
        'Confirmar registre',
    ]

    // Poblaciones

    const [fetchPoblaciones, fetchPoblacionesData] = useFetch('GET', `/parametros/poblaciones`)
    const [poblaciones, setPoblaciones] = useState([]);

    useEffect(() => {

        if (formRegisterExterno.codigo_postal?.length === 5) {

            fetchPoblaciones({
                params: {
                    codigo_postal: formRegisterExterno.codigo_postal
                }
            })
        } else {
            setPoblaciones([])
            handleFormChangeRegisterExterno({
                ...formRegisterExterno,
                provincia: '',
                poblacion: '',
                pais: '',
            })
        }

    }, [formRegisterExterno.codigo_postal])

    useEffect(() => {

        if (fetchPoblacionesData.data) {

            if (fetchPoblacionesData.data.status === 'Success') {

                const poblaciones = fetchPoblacionesData.data.data

                let poblacionesTemp = poblaciones.map(item => {
                    return ({
                        id: item.poblacion,
                        nombre: item.poblacion
                    })
                })

                setPoblaciones(poblacionesTemp)

            } else {
                handleFormChangeRegisterExterno({
                    ...formRegisterExterno,
                    provincia: '',
                    poblacion: '',
                    pais: ''
                })
            }
        }

    }, [fetchPoblacionesData])

    useEffect(() => {

        if (formRegisterExterno.poblacion && fetchPoblacionesData.data) {

            const poblacionesTemp = [...fetchPoblacionesData.data.data]

            const resp = poblacionesTemp.find(item => item.poblacion === formRegisterExterno.poblacion)

            handleFormChangeRegisterExterno({
                ...formRegisterExterno,
                provincia: resp?.provincia,
                pais: resp?.pais,
            })
        }

    }, [formRegisterExterno.poblacion, fetchPoblacionesData.data])

    const [showModalProteccionDatos, setShowModalProteccionDatos] = useState(false)

    const handleCloseModalProteccionDatos = () => setShowModalProteccionDatos(false)
    const handleShowModalProteccionDatos = () => setShowModalProteccionDatos(true)

    const staticTipoViasServices = TipoViasServices.GET()

    // -------------------- Autocompletado de Google

    const getPlaceDetails = (placeId) => {

        return new Promise(function (resolve, reject) {

            placesService.getDetails({
                placeId,
            },
                (place) => {
                    resolve(place);
                }
            );
        });
    };

    const handleSelectOption = async (place) => {

        const placeDetails = await getPlaceDetails(place.place_id)

        console.log('placeDetails', placeDetails);

        const location = _.groupBy(placeDetails?.address_components, 'types')

        handleFormChangeRegisterExterno({
            ...formRegisterExterno,
            codigo_postal: location?.['postal_code']?.[0]?.long_name || '',
            numero: location?.['street_number']?.[0]?.long_name || '',
            calle: location?.['route']?.[0]?.long_name || '',
            lat: String(placeDetails?.geometry?.location?.lat() || ''),
            lng: String(placeDetails?.geometry?.location?.lng() || ''),
        })
        handleResetPredictions()

    }

    const handleResetPredictions = () => {

        getPlacePredictions({ input: '' });
    }

    const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading, } = usePlacesService({
        apiKey: process.env.REACT_APP_GOOGLE_GEO_API_KEY,
        options: {
            types: ['address'],
        }
    });

    const [isHoverAutocomplete, setisHoverAutocomplete] = useState(false);

    // -------------------- End Autocompletado de Google

    return (
        <>

            <div className="w-100 d-flex justify-content-center align-items-md-center flex-md-column flex-grow-1 mb-lg-5 animate__animated animate__fadeInUp">

                <div className='col-11 col-sm-12 col-lg-12 col-xl-12 col-xxl-12 mb-5 mb-lg-0 pt-5 mt-md-0 mt-5 mt-md-0'>

                    <h4 className='text-center my-4'>Proves de laboratori: Test aluminós</h4>

                    <Tabs
                        defaultActiveKey="home"
                        transition={false}
                        id="noanim-tab-example"
                    >
                        <Tab eventKey="home" title={<span><FontAwesomeIcon icon={faUser} className='me-2' />Col·legiats CATEB</span>}>

                            <div className="d-flex justify-content-center" id='container-colegiats'>
                                <div className='col-12 col-sm-6 col-lg-6 col-xl-5 col-xxl-4'>
                                    <Card className="shadow-sm">
                                        {/* <Card.Img variant="top" src="images/logo.png" id="logo" /> */}
                                        <Card.Body className="px-4 py-md-4">
                                            <h5 className='mt-2 mb-3'>Inicia sessió</h5>
                                            <Form>
                                                <Form.Group className="mb-3">
                                                    <InputText
                                                        label='Número de col·legiat'
                                                        className=''
                                                        name='num_colegiado'
                                                        value={formLoginColegiado.num_colegiado}
                                                        onChange={handleInputChangeLoginColegiado}
                                                        placeholder="num_colegiado"
                                                        validation={valuesValidLogin}
                                                        fetchData={fetchLoginColegiadoData}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="mb-3">
                                                    <InputText
                                                        label={Lang('CONTRASENIA')}
                                                        className=''
                                                        name='password'
                                                        type="password"
                                                        value={formLoginColegiado.password}
                                                        onChange={handleInputChangeLoginColegiado}
                                                        placeholder="password"
                                                        validation={valuesValidLogin}
                                                        fetchData={fetchLoginColegiadoData}
                                                    />
                                                </Form.Group>

                                                <div className="text-center">
                                                    <MsgInputServer obj={fetchLoginColegiadoData.data} />
                                                </div>

                                                <div className="text-end mt-4 d-flex flex-wrap justify-content-end align-items-center">
                                                    <ButtonSpinner
                                                        variant="primary" type="submit" className="px-4 w-100"
                                                        handleSubmit={handleSubmitFormLoginColegiado} fetchDataLoading={[fetchLoginColegiadoData.loading]}
                                                        value='Iniciar sessió'
                                                    />
                                                </div>
                                            </Form>
                                        </Card.Body>
                                        <Card.Footer className="text-muted">
                                            {/* <button type="button" id="passwordHelp" className="form-text my-0" onClick={handleShow}>
                                                {Lang('OLVIDASTE_CONTRASENA')}
                                            </button> */}
                                            {/* <a href="https://caateebcn.force.com/SiteLogin?startURL=%2FVirtualOffice_Home" id="passwordHelp" className='form-text my-0'>
                                                {Lang('OLVIDASTE_CONTRASENA')}
                                            </a> */}
                                        </Card.Footer>

                                    </Card>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="profile" title={<span><FontAwesomeIcon icon={faUserFriends} className='me-2' />Altres usuaris</span>}>

                            <div className="d-flex justify-content-center flex-wrap flex-column align-items-center align-items-lg-start flex-lg-row" id='container-no-colegiats'>
                                <div className='col-12 col-sm-8 col-md-6 col-lg-5 col-xl-5 col-xxl-4'>
                                    <Card className="shadow-sm">
                                        {/* <Card.Img variant="top" src="images/logo.png" id="logo" /> */}
                                        <Card.Body className="px-4 py-md-4">
                                            <h5 className='mt-2 mb-3'>Registra't</h5>
                                            <Form>
                                                <button type="button" className="form-text my-0 btn btn-outline-primary w-100" onClick={handleShowModalRegistro}>
                                                    Registrar-me
                                                </button>
                                            </Form>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='col-12 col-sm-8 col-md-6 col-lg-5 col-xl-5 col-xxl-4'>

                                    <Card className="shadow-sm">
                                        {/* <Card.Img variant="top" src="images/logo.png" id="logo" /> */}
                                        <Card.Body className="px-4 py-md-4">
                                            <h5 className='mt-0 mb-3'>Inicia sessió</h5>

                                            <Form>

                                                <Form.Group className="mb-3">
                                                    <InputText
                                                        label='NIF'
                                                        className=''
                                                        name='documento'
                                                        value={formLoginExterno.documento}
                                                        onChange={handleInputChangeLoginExterno}
                                                        placeholder="documento"
                                                        validation={valuesValidLoginExterno}
                                                        fetchData={fetchLoginExternoData}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="mb-3">
                                                    <InputText
                                                        label={Lang('CONTRASENIA')}
                                                        className=''
                                                        name='password'
                                                        type="password"
                                                        value={formLoginExterno.password}
                                                        onChange={handleInputChangeLoginExterno}
                                                        placeholder="password"
                                                        validation={valuesValidLoginExterno}
                                                        fetchData={fetchLoginExternoData}
                                                    />
                                                </Form.Group>

                                                <div className="text-center">
                                                    <MsgInputServer obj={fetchLoginExternoData.data} />
                                                </div>

                                                <div className="text-end mt-4 d-flex flex-wrap justify-content-end align-items-center">
                                                    <ButtonSpinner
                                                        variant="primary" type="submit" className="px-4 w-100"
                                                        handleSubmit={handleSubmitFormLoginExterno} fetchDataLoading={[fetchLoginExternoData.loading]}
                                                        value={Lang('INICIAR_SESION')}
                                                    />
                                                </div>
                                            </Form>
                                        </Card.Body>
                                        <Card.Footer className="text-muted">
                                            <button type="button" id="passwordHelp" className="form-text my-0" onClick={handleShow}>
                                                {Lang('OLVIDASTE_CONTRASENA')}
                                            </button>
                                        </Card.Footer>

                                    </Card>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>

                    {
                        isBetweenDates('2025-01-07', '2025-02-07') &&
                        <div className="row justify-content-center mt-3">
                            <div className="col-11 col-lg-9">
                                <Alert variant='warning' className='py-2'>
                                    {/* <p className='mb-2'>
                                        <small>Us informem que del 23 de desembre de 2024 al 6 de gener de 2025 el laboratori romandrà tancat.</small>
                                    </p> */}
                                    <p className='mb-0'>
                                        <small>A partir del dia 10 de gener les mostres de test aluminós a Barcelona han de lliurar-se al carrer Buenos Aires 21, baixos.</small>
                                    </p>
                                </Alert>
                            </div>
                        </div>
                    }

                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='h5'>Recuperar password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <Form onSubmit={handleSubmitRecuperarPassword}>
                                    <Form.Group className="mb-3">
                                        <InputText
                                            label='NIF'
                                            className=''
                                            name='nif'
                                            value={formLoginColegiadoRecuperarPassword.nif}
                                            onChange={handleInputChangeRecuperarPassword}
                                            placeholder="NIF"
                                            validation={valuesValidRecuperarPassword}
                                            fetchData={fetchRecuperarPasswordData}
                                        />

                                    </Form.Group>

                                    <div className="text-center">
                                        <MsgInputServer obj={fetchRecuperarPasswordData.data} />
                                    </div>

                                </Form>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" onClick={handleClose}>
                        Tancar
                    </Button>
                    <ButtonSpinner
                        variant="primary" type="submit" className="ms-2"
                        handleSubmit={handleSubmitRecuperarPassword} fetchDataLoading={[fetchRecuperarPasswordData.loading]}
                        value='Recuperar'
                    />
                </Modal.Footer>
            </Modal>

            <Modal show={showModalRegistro} onHide={handleCloseModalRegistro} size='lg' id='ModalRegistro'>
                <Modal.Header className=''>
                    <Modal.Title className='h5 mx-auto'>Registre nou usuari</Modal.Title>
                    <button type="button" className="btn-close mx-0" aria-label="Close" onClick={handleCloseModalRegistro}></button>
                </Modal.Header>
                <Modal.Body className='p-0'>

                    <section style={{ 'backgroundColor': '#f5f5f5' }}>
                        <div className="container animate__faster animate__animated animate__fadeInLeft">
                            <div className="row pt-3 pb-4 px-3">
                                <div className="col-12">
                                    <h5 className='mb-4'>Dades personals</h5>
                                </div>

                                <Form.Group className="mb-3 col-sm-5 col-lg-3">
                                    <InputSelect
                                        label='Tipus document*'
                                        className=''
                                        name='tipo_documento'
                                        // values={[
                                        //     { id: 'nif', nombre: 'NIF' },
                                        //     { id: 'nie', nombre: 'NIE' },
                                        //     { id: 'cif', nombre: 'CIF' },
                                        // ]}
                                        values={fetchParametrosData.data?.data?.tipos_documento}
                                        value={formRegisterExterno.tipo_documento}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="tipo_documento"
                                        validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-sm-7 col-lg-4">
                                    <InputText
                                        label='Document*'
                                        className=''
                                        name='documento'
                                        value={formRegisterExterno.documento}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="documento"
                                        validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group>

                                <div className="col-12"></div>

                                <Form.Group className="mb-3 col-lg-4">
                                    <InputText
                                        label='Nom*'
                                        className=''
                                        name='nombre'
                                        value={formRegisterExterno.nombre}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="nombre"
                                        validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-lg-5">
                                    <InputText
                                        label='Cognoms*'
                                        className=''
                                        name='apellidos'
                                        value={formRegisterExterno.apellidos}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="apellidos"
                                        validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group>

                                <div className="col-12">
                                    <div className="mb-4 d-flex align-items-center">
                                        <h5 className='me-2 mb-0'>Dades Adreça</h5>

                                    </div>
                                </div>

                                <Form.Group className="mb-4 col-md-6 col-lg-3">
                                    <InputSelect
                                        label='Tipus via *'
                                        className=''
                                        name='tipovia'
                                        values={getSelectByKeys(staticTipoViasServices)}
                                        value={formRegisterExterno.tipovia}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="tipovia"
                                        validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-lg-7 position-relative">

                                    <div className="form-floating">
                                        {/* <Autocomplete
                                                apiKey={process.env.REACT_APP_GOOGLE_GEO_API_KEY}
                                                onPlaceSelected={(place) => console.log('temp', formRegisterExterno)}
                                                options={
                                                    {
                                                        types: ['address'],
                                                    }
                                                }
                                                className='form-control'
                                                value={formRegisterExterno.calle}
                                                name='calle'
                                                onChange={handleInputChangeRegisterExterno}
                                                autoComplete='one-time-code'
                                            />
                                        <label>Adreça*</label> */}

                                        <InputText
                                            label='Adreça*'
                                            className=''
                                            name='calle'
                                            value={formRegisterExterno.calle}
                                            onChange={(evt) => {
                                                getPlacePredictions({ input: evt.target.value });
                                                handleInputChangeRegisterExterno(evt);
                                            }}
                                            placeholder="calle"
                                            // validation={valuesValidRegisterExterno}
                                            fetchData={fetchRegisterExternoData}
                                            onBlur={(e) => { isHoverAutocomplete ? e.preventDefault() : handleResetPredictions() }}
                                        />
                                        <ListAutocomplete
                                            list={placePredictions}
                                            handleClick={handleSelectOption}
                                            nameTitle='description'
                                            onMouseOver={() => setisHoverAutocomplete(true)}
                                            onMouseOut={() => setisHoverAutocomplete(false)}
                                        />

                                        {/* <input ref={ref} style={{ width: "90%" }} defaultValue="Amsterdam" onChange={handleInputChangeRegisterExterno}/> */}

                                    </div>
                                </Form.Group>


                                <div className="col-12"></div>

                                <Form.Group className="mb-4 col-md-6 col-lg-2">
                                    <InputText
                                        label='Número *'
                                        className=''
                                        name='numero'
                                        value={formRegisterExterno.numero}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="numero"
                                        // validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-4 col-md-6 col-lg-2">
                                    <InputText
                                        label='Pis'
                                        className=''
                                        name='piso'
                                        value={formRegisterExterno.piso}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="piso"
                                        // validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-4 col-md-6 col-lg-2">
                                    <InputText
                                        label='Porta'
                                        className=''
                                        name='puerta'
                                        value={formRegisterExterno.puerta}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="puerta"
                                        // validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-4 col-md-6 col-lg-2">
                                    <InputText
                                        label='Escala'
                                        className=''
                                        name='escalera'
                                        value={formRegisterExterno.escalera}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="escalera"
                                        // validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group>

                                <div className="col-12"></div>

                                {/* <Form.Group className="mb-3 col-sm-6 col-lg-3">
                                    <InputSelect
                                        label='País*'
                                        className=''
                                        name='pais'
                                        values={fetchParametrosData.data?.data?.paises}
                                        value={formRegisterExterno.pais}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="pais"
                                        validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group> */}

                                <Form.Group className="mb-3 col-sm-6 col-lg-3">
                                    <InputText
                                        label='Codi Postal*'
                                        className=''
                                        name='codigo_postal'
                                        value={formRegisterExterno.codigo_postal}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="codigo_postal"
                                        validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-sm-6 col-lg-5">
                                    <InputSelect
                                        label='Població *'
                                        className=''
                                        name='poblacion'
                                        // values={[
                                        //     { id: 'espana', nombre: 'España' },
                                        // ]}
                                        values={poblaciones}
                                        value={formRegisterExterno.poblacion}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="poblacion"
                                        // validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                        disabled={!fetchPoblacionesData.data || formRegisterExterno.codigo_postal?.length !== 5}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-sm-6 col-lg-4">
                                    <InputText
                                        label='Comarca*'
                                        className=''
                                        name='comarca'
                                        value={formRegisterExterno.comarca}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="comarca"
                                        // validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group>

                                <div className="col-12"></div>

                                <Form.Group className="mb-3 col-sm-7 col-lg-4">
                                    <InputText
                                        label='Telèfon de contacte*'
                                        className=''
                                        name='telefono'
                                        value={formRegisterExterno.telefono}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="telefono"
                                        validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-lg-6">
                                    <InputText
                                        label='Adreça electrònica*'
                                        className=''
                                        name='email'
                                        value={formRegisterExterno.email}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="email"
                                        validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group>

                                <div className="col-12">
                                    <h5 className='mb-4 mt-3'>Dades d'accés</h5>
                                </div>

                                <Form.Group className="mb-3 col-lg-6">
                                    <InputText
                                        label='Contrasenya*'
                                        className=''
                                        name='password'
                                        type='password'
                                        value={formRegisterExterno.password}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="password"
                                        validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-lg-6">
                                    <InputText
                                        label='Repetir contrasenya*'
                                        className=''
                                        type='password'
                                        name='password_confirmation'
                                        value={formRegisterExterno.password_confirmation}
                                        onChange={handleInputChangeRegisterExterno}
                                        placeholder="password_confirmation"
                                        validation={valuesValidRegisterExterno}
                                        fetchData={fetchRegisterExternoData}
                                    />
                                </Form.Group>

                                <div className="col-12" key={'hola'}>
                                    <Form.Check
                                        type='checkbox'
                                        name='manifesto'
                                        checked={formRegisterExterno.manifesto === 'true' ? true : false}
                                        // onChange={handleChangeManifesto}
                                        onClick={(e) => {
                                            console.log(e.target.checked)
                                            if (e.target.checked === true) {
                                                e.preventDefault()
                                                handleShowModalProteccionDatos()
                                            } else {
                                                handleChangeManifesto(e)
                                            }
                                        }}
                                        id='default-checkbox'
                                        label={
                                            <>
                                                <small>
                                                    *MANIFESTO que he llegit el contingut de la informació precedent relativa a la protecció de dades de caràcter personal
                                                </small>
                                                {/* <a href="https://www.apabcn.cat/ca_es/Documents/CL3F.pdf" target='_blank' rel="noopener noreferrer" className='ms-2 color-primary'>
                                                    <FontAwesomeIcon icon={faFilePdf} className='me-2' style={{ 'fontSize': '1.5rem' }} />
                                                </a> */}
                                            </>
                                        }
                                    />
                                    <MsgInput obj={valuesValidRegisterExterno} name='manifesto' />

                                </div>
                                <div className="col-12 mt-2 text-center">
                                    <MsgInputServer obj={fetchRegisterExternoData.data} className='mt-4' />
                                </div>

                            </div>
                        </div>

                    </section>
                </Modal.Body>
                <Modal.Footer className=''>
                    <Button variant="default" onClick={handleCloseModalRegistro} className='px-3'>
                        Sortir
                    </Button>
                    <ButtonSpinner
                        variant="primary" type="submit" className="ms-3 px-3"
                        handleSubmit={handleSubmitFormRegisterExterno} fetchDataLoading={[fetchRegisterExternoData.loading]}
                        value='Registrar-me'
                    />
                </Modal.Footer>
            </Modal>

            <ModalProteccionDatos
                showModalProteccionDatos={showModalProteccionDatos}
                setShowModalProteccionDatos={setShowModalProteccionDatos}
                handleCloseModalProteccionDatos={handleCloseModalProteccionDatos}
                handleSubmit={handleChangeManifesto}
            />

            <ModalStatus setShowStatus={setShowStatusVerify2} showStatus={showStatusVerify2}>
                <div className="py-0 text-center">
                    <h5 className='mb-4 fw-normal'>Confirma registre</h5>
                    <p className='mb-0'>Hem enviat un codi de confirmació al teu correu electrònic.
                        <br />
                        Si no has rebut el codi posat en contacte amb nosaltres al telf. 932 40 20 60. Abans, revisa la teva safata d'spam</p>
                </div>
            </ModalStatus>

            <ModalStatus setShowStatus={setShowStatusRecuperarPassword} showStatus={showStatusRecuperarPassword}>
                <div className="py-3 text-center">
                    <h5 className='mb-0 fw-normal'>{fetchRecuperarPasswordData.data?.message}</h5>
                </div>
            </ModalStatus>
        </>
    )
}